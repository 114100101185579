'use client';

import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.css';
import ClockIcon from './ClockIcon';
type Props = {
  size: 'small' | 'medium' | null;
  text?: string;
  //Icon may be dynamic in the future, for now it's static - Clock
};
export const CardPlaceholder = ({
  text,
  size
}: Props) => {
  return <div className={classNames(styles['card-placeholder-wrapper'], {
    [styles[size + '_card']]: size
  })} data-sentry-component="CardPlaceholder" data-sentry-source-file="index.tsx">
			<ClockIcon data-sentry-element="ClockIcon" data-sentry-source-file="index.tsx" />
			<p>{text}</p>
		</div>;
};