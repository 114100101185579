'use client';

import classNames from 'classnames';
import styles from './styles.module.css';
import { ETabIndexesOrderingLevels } from '@/types/types';
import React, { useState } from 'react';
type Props = {
  title: string;
  content: string;
};

/**
 * Slightly different than the SeoPanel component @ src\components\SeoPanel, but different enough to require another component
 */
export const TogglePanel = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  if (!props?.title?.length || !props?.content?.length) {
    return null;
  }
  const toggleExpanded = (event: any) => {
    if (event.detail === 0) return;
    setIsExpanded(prevState => !prevState);
  };
  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      setIsExpanded(prevState => !prevState);
    }
  };
  const buttonText = isExpanded ? 'Show less' : 'Show more';
  return <div className={classNames(styles['seo-panel'], 'seo-panel', {
    'is-expanded': isExpanded,
    [styles['is-expanded']]: isExpanded
  })} data-sentry-component="TogglePanel" data-sentry-source-file="index.tsx">
			<div className={styles.toggledContent}>
				<h2 className={styles['title']}>{props?.title}</h2>
				<p className={styles['content']}>{props?.content}</p>
			</div>
			<div className={styles.toggleButton}>
				<button id='show-more' className={styles.seeMoreButton} type='button' aria-label={buttonText} onClick={toggleExpanded} onKeyDown={handleKeyPress} tabIndex={ETabIndexesOrderingLevels.AUTO} aria-expanded={isExpanded}>
					{buttonText}
				</button>
			</div>
		</div>;
};