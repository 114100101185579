import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';
export const ThumbnailIcon = (props: {
  src: string;
  className?: string;
  alt?: string;
  width?: number;
  height?: number;
  marginStyle?: string;
  isThumbnail?: boolean;
}) => {
  if (!props.isThumbnail) return null;
  const altStr = props?.alt || 'thumbnail icon';
  const width = props?.width ? props?.width : 24;
  const height = props?.height ? props?.height : 24;
  const noImg = !props?.src || props?.src === '' ? ' noImg' : '';
  const classStr = props?.className ? `thumbnail-icon ${props?.className + noImg}` : `thumbnail-icon ${noImg}`;
  const marginStyleStr = props?.marginStyle ? props?.marginStyle : '';

  // if src is type svg, use eager loading
  const srcType: any = props?.src.split('.').pop();
  const srcTypeArr = ['svg'];
  const srcTypeIsSvg = srcTypeArr.includes(srcType);
  const loadingTypeStr = srcTypeIsSvg ? 'eager' : 'lazy';
  return props?.src ? <Image alt={altStr} src={props?.src} loading={loadingTypeStr} width={width} height={height} className={classNames('thumbnail-icon')} style={marginStyleStr as React.CSSProperties} data-sentry-element="Image" data-sentry-component="ThumbnailIcon" data-sentry-source-file="index.tsx" /> : <div className={classStr} data-sentry-component="ThumbnailIcon" data-sentry-source-file="index.tsx"></div>;
};